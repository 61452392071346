import React from "react";
import { connect } from "react-redux";

import { Box, Rule, Mobile } from "../Bitter";
import { Colors } from "../Helpers";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Mobile
        render={(mobile) => (
          <>
            <Box
              width="100%"
              mode="padding"
              left={mobile ? 15 : 40}
              right={mobile ? 15 : 40}
              top={20}
              bottom={10}
            >
              <Box display="flex">
                <Box flex={1}>
                  <img className="logo" src="/assets/i/logo.svg" />
                </Box>
                <Box left={mobile ? 20 : 0} flex={1}>
                  <Box display="flex" wrap="wrap" bottom={5} justify={mobile ? "flex-end" : null}>
                    <Rule
                      rule="Large"
                      display="inline-block"
                      bottom={5}
                      right={30}
                      to="#work"
                    >
                      Work
                    </Rule>
                    <Rule
                      rule="Large"
                      display="inline-block"
                      bottom={5}
                      to="#contact"
                    >
                      Contact
                    </Rule>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              identifier="hero"
              width="100%"
              top={mobile ? 20 : 80}
              bottom={mobile ? 20 : 80}
              mode="padding"
              left={mobile ? 15 : 40}
              right={mobile ? 15 : 40}
            >
              <Box display="flex" direction={mobile ? "column-reverse" : "row"}>
                <Box flex={2}>
                  <Rule rule="Heading">
                    My name is Buket and I am a UI/UX designer with three years
                    of experience. I am also an architect student, and I have a
                    keen interest in theatre and acting. I believe that my
                    diverse range of interests and skills makes me an excellent
                    web designer, and I am passionate about creating beautiful,
                    user-friendly websites & applications.
                  </Rule>
                </Box>
                <Box flex={1}>
                  <a
                    href="https://www.instagram.com/tugcebuketn/"
                    target="_blank"
                  >
                    <img class="card --right" src="/assets/i/image-hero.png" />
                  </a>
                </Box>
              </Box>
            </Box>
            <Box
              identifier="work"
              width="100%"
              top={mobile ? 20 : 80}
              bottom={mobile ? 20 : 80}
              mode="padding"
              left={mobile ? 15 : 40}
              right={mobile ? 15 : 40}
            >
              <Box display="flex" direction={mobile ? "column" : "row"}>
                <Box flex={1}>
                  <a href="https://dribbble.com/bukeet" target="_blank">
                    <img class="card --left" src="/assets/i/image-works.png" />
                  </a>
                </Box>
                <Box flex={2}>
                  <Rule rule="Heading">
                    I have worked on a variety of web design projects, from
                    small personal websites to large applications. No matter the
                    size or scope of the project, I always strive to create the
                    best possible experience for the user. I am constantly
                    exploring new web design trends and technologies, and I am
                    always looking for ways to improve my skills.
                  </Rule>
                </Box>
              </Box>
            </Box>
            <Box
              identifier="contact"
              width="100%"
              top={mobile ? 20 : 80}
              bottom={mobile ? 20 : 60}
              mode="padding"
              left={mobile ? 15 : 40}
              right={mobile ? 15 : 40}
            >
              <Box display="flex" direction={mobile ? "column" : "row"}>
                <Box flex={2}>
                  <Rule rule="Heading" bottom={20}>
                    If you are looking for a web designer who is creative,
                    passionate, and dedicated to creating amazing websites, then
                    please contact me. I would be thrilled to work with you on
                    your next web design project.
                  </Rule>
                </Box>
                <Box flex={1}>
                  <Rule
                    rule="Heading"
                    to="mailto:hello@buket.design"
                    color={Colors.primary}
                  >
                    hello@buket.design
                  </Rule>
                </Box>
              </Box>
            </Box>
            <Box
              width="100%"
              mode="padding"
              left={mobile ? 15 : 40}
              right={mobile ? 15 : 40}
              top={20}
              bottom={10}
              style={{ borderTop: "1px solid #DDD" }}
            >
              <Box display="flex" direction={mobile ? "column" : "row"}>
                <Box flex={1}>
                  <img className="logo" src="/assets/i/logo.svg" />
                  <Rule rule="Large" display="block" bottom={10}>
                    All rights reserved
                  </Rule>
                </Box>
                <Box left={mobile ? 0 : 20} flex={1}>
                  <Rule rule="Large" display="block" bottom={15}>
                    hello@buket.design
                  </Rule>
                  <Box display="flex" wrap="wrap" bottom={5}>
                    <Rule
                      rule="Large"
                      display="inline-block"
                      bottom={5}
                      right={30}
                      to="https://www.instagram.com/tugcebuketn/"
                      target="_blank"
                    >
                      instagram
                    </Rule>
                    <Rule
                      rule="Large"
                      display="inline-block"
                      bottom={5}
                      to="https://dribbble.com/bukeet"
                      target="_blank"
                    >
                      dribbble
                    </Rule>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);

import React, { useEffect, useState } from "react";
import { ScreenClassRender } from "react-grid-system";
import { useMediaQuery } from "react-responsive";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

const Component = ({ render }) => {
  const minimum = useMediaQuery({ query: `(max-width: 320px)` });
  const { width } = useWindowSize();

  return (
    <ScreenClassRender
      render={(screen) => {
        const mobile = ["xs", "sm"].includes(screen);

        const gutters = {
          xs: "100%",
          sm: "540px",
          md: "740px",
          lg: "960px",
          xl: "1140px",
          xxl: "1540px",
        };

        const spacings = {
          xs: 15,
          sm: 540,
          md: 740,
          lg: 960,
          xl: 1140,
          xxl: 1540,
        };

        const spacing = screen === "xs" ? 15 : width - spacings[screen];

        const gutter =
          screen === "xs" ? 15 : "calc((100vw - " + gutters[screen] + ") / 2)";

        return render
          ? render(mobile, minimum, gutter, spacing, screen)
          : undefined;
      }}
    />
  );
};

export default Component;

import React from "react";
import Text from "./Text";
import { Mobile } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = (props) => {
  return (
    <Mobile
      render={(mobile) => {
        const { rule = "Body" } = props;
        return rule === "Title" ? (
          <Text
            display="block"
            size={24}
            family="Visby CF"
            weight={700}
            {...props}
          />
        ) : rule === "Brand" ? (
          <Text
            display="block"
            size={28}
            family="Visby CF"
            weight={700}
            {...props}
          />
        ) : rule === "Large" ? (
          <Text
            display="block"
            size={18}
            family="Visby CF"
            weight={400}
            {...props}
          />
        ) : rule === "Heading" ? (
          <Text
            display="block"
            size={mobile ? 22 : 36}
            family="Visby CF"
            weight={400}
            {...props}
          />
        ) : (
          <Text {...props} />
        );
      }}
    />
  );
};

export default Component;

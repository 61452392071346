import React from "react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import Helmet from "react-helmet";
import { Store, Navigator, API } from "./Modules";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  return (
    <Provider store={Store}>
      <Helmet>
        <title>Buket Nevrekop - Freelance UI/UX Designer</title>
      </Helmet>
      <Navigator />
      <Toaster position="bottom-center" />
      <AnimatedCursor
        color="87,16,255"
        innerSize={15}
        outerSize={15}
        innerScale={1}
        outerScale={2}
        outerAlpha={0.3}
      />
    </Provider>
  );
};

export default App;
